<template lang="">
    <div class="application-summary-section">
        <b-card class="st-section st-section__summary mt-6">
            <div class="st-section-header">
                <i v-if="isSigned || isDefault" :class="['fas fa-check-circle', {'text-success': isSigned}]"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.SIGN_TYPE_TITLE') }}</span>
            </div>
            <div class="st-section-body">
                <div class="font-weight-bold">{{ signTypeText }}</div>
                <div>{{ `${userFullName} - ${applicantUserPhone}` }}</div>
                <div v-if="isSignOnline && signDetails.package">
                    {{ signDetails.package.name }}
                </div>
            </div>
            <template #footer v-if="isSignOnline">
                <span class="text-success cursor-default">{{ $t('APPLICATION.SUMMARY.SIGN_TYPE_FOOTER') }}</span>
            </template>
        </b-card>
         <b-card class="st-section st-section__summary mt-6">
             <div class="st-section-header">
                <i v-if="isCompleted || isDefault" :class="['fas fa-check-circle', {'text-success': isCompleted}]"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.APPLICATION_DETAIL_TITLE') }}</span>
            </div>
            <div class="st-section-body">
                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.APPLICATION_OBJECTIVE') }}</div>
                <div>{{ applicationTypeName }}</div>

                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.APPLICANT') }}</div>
                <div>{{ userFullName }} - {{ userTypeName }}</div>
            </div>
            <template #footer>
                <span class="text-success cursor-pointer" @click="viewRealEstatesSummary">{{ realEstateCountText }}</span>
            </template>
        </b-card>
         <b-card class="st-section st-section__summary mt-6">
             <div class="st-section-header">
                <i v-if="isPaid" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-check-circle text-dark-light"></i>
                <span>{{ $t('APPLICATION.SUMMARY.PAYMENT_TYPE_TITLE') }}</span>
            </div>
            <div class="st-section-body">
                <div class="font-weight-bold">{{ $t('APPLICATION.SUMMARY.PAYMENT_TYPE') }}</div>
                <div> {{ summaryPaymentText }} </div>
            </div>
        </b-card>

        <real-estate-summary-modal :items="formModels.real_estate_target" ref="realEstateSummary"></real-estate-summary-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import RealEstateSummaryModal from './RealEstateSummaryModal.vue';

export default {
    name: 'ApplicationSummaryDetails',
    props: [ 'formModels', 'signDetails' ],
    components: {
        RealEstateSummaryModal
    },
    computed: {
        ...mapGetters({
           currentUser: 'auth/currentUser',
           record: 'applications/form/record',
        }),
        applicantUser() {
            return this.formModels?.applicant_form;
        },
        userFullName() {
            return `${this.applicantUser?.first_name} ${this.applicantUser?.last_name}`;
        },
        applicantUserPhone() {
            return this.applicantUser?.phone_number;
        },
        signTypeText() {
            return this.signDetails.isOnline
            ? this.$t('APPLICATION.SIGNATURE.ONLINE.SUMMARY_TEXT')
            : this.$t('APPLICATION.SIGNATURE.OFFLINE.SUMMARY_TEXT');
        },
        applicationTypeName() {
            return this.formModels?.application_type_name;
        },
        placementNo(){
            if (this.formModels?.real_estate_target) {
                return this.formModels.real_estate_target.length;
            }

            return 0;
        },
        userTypeName() {
            if (this.applicantUser?.user_type_name === 'legal_entity') {
                return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL");
            }

            return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL");
        },
        realEstateCountText() {
            return this.$tc('APPLICATION.SUMMARY.SELECTED_REAL_ESTATES_TEXT', this.placementNo, { count: this.placementNo});
        },
        isSignOnline() {
            return !!this.signDetails?.isOnline;
        },
        isSigned() {
            return !!this.record?.is_signed;
        },
        isDefault() {
            return !this.record;
        },
        isCompleted() {
            return this.formModels?.beneficiary_users?.length && this.formModels?.real_estate_target?.length;
        },
        isPaid() {
            return this.record?.is_paid;
        },
        summaryPaymentText() {
            if (this.record?.is_paid) {
                return this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT_SUCCESS');
            } else {
                return this.record?.payment_status && this.record?.payment_status === 'failed' ?
                        this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT_FAILED') :
                        this.$t('APPLICATION.SUMMARY.PAYMENT_TYPE_HELP_TEXT')
            }
        }
    },
    methods: {
        viewRealEstatesSummary() {
            this.$refs.realEstateSummary.show();
        },
    },
};
</script>
